import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { InfoPage } from '../interfaces/info-page.interfaces';
import { Company } from '../models/NUTR/company';

@Injectable({
  providedIn: 'root'
})
export class InfoPageService {
  info:InfoPage = {};
  company:Company

  constructor( private http:HttpClient) {
    // console.log('Info página cargada');
    this.http.get('assets/data/data-pagina.json').subscribe((resp:InfoPage) => {
          this.info = resp;
          if(!resp['autenticated']) this.info.isLoggedIn = false;
          console.log('Esta autentificado ? ->',resp['autenticated']);
        });

   }

   loginTemplate(){
    this.info.isLoggedIn = false;
   }
   pageTemplate(){
    this.info.isLoggedIn = true;
   }
}
