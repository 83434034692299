import { Component } from '@angular/core';
import { InfoPageService } from './services/info-page.service';
import { setTheme } from 'ngx-bootstrap/utils';
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {

  constructor(public infoPageService: InfoPageService) {
    setTheme('bs4');
  }

}
