import { NgModule } from '@angular/core';
import { OrderPipe } from './order.pipe';
import { GuionPipe } from './guion.pipe';
import { orderFoodPipe ,orderFoodEditionPipe} from './orderByFood.pipe';



@NgModule({
  declarations: [OrderPipe, GuionPipe, orderFoodPipe,orderFoodEditionPipe],
  exports: [OrderPipe, GuionPipe, orderFoodPipe,orderFoodEditionPipe],
  providers: [OrderPipe, GuionPipe, orderFoodPipe,orderFoodEditionPipe]
})
export class OrderModule {}