import { Component, ViewEncapsulation } from '@angular/core';
import { UserService } from '../../../services/user-services/user.service';
import { Router } from '@angular/router';
import { OrderPipe } from '../../../pipes/order-pipe/order.pipe';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { User } from 'src/app/models/SYS/user';
import { InfoPageService } from 'src/app/services/info-page.service';
declare var $: any;
@Component({
  selector: 'main_menu',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavbarComponent {

  user: User
  appSys: any = []
  order: string = 'id';
  appInstans: any = {}
  location: Location;
  reverse: boolean = false;

  constructor(private router: Router, location: Location, private orderPipe: OrderPipe,
    private authenticationService:UserService, public infoPageService:InfoPageService) {
      this.infoPageService.pageTemplate();
    this.location = location;
  }

  ngOnInit() {
    if (localStorage.getItem('user')) {
      this.user = JSON.parse(localStorage.user);
      this.setMenu(this.user)
    }
  }
  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }
  searchAplication = function (apps, url) {
    var app = apps.find(function (app) {
      var bandera = app.url == url;
      if (bandera) {
        bandera = app
      } else {
        bandera = null
      }
      return bandera;
    });
    app = app ? app : []
    this.appInstans = app;
  }
  navegatTo = function (app) {
    this.appInstans = app;
    var bandera= app.SubAppSys?(app.SubAppSys.length==0):true
    if (bandera) {
      /* if (!this.appInstans.url) { */
        this.router.navigate([app.url]);
        $('#'+app.id+"1").toggleClass('active');
        $('#'+app.id+"-icon").toggleClass('active');
     /*  } else {
        this.router.navigate(['Dashboard']);
      } */
    }else{
      $('#'+app.id+"1").toggleClass('menu-activo');
    }
  }
  navegatToUrl = function (url) {
    this.router.navigate([url]);
  }
  public setMenu = function (user) {
    this.appSys = []
    for (var j = 0; j < user.rol.rolAplications.length; j++) {
      user.rol.rolAplications[j].aplication.url = user.rol.rolAplications[j].aplication.url.replace("?", user.person.id + "");
      if (user.rol.rolAplications[j].aplication.fatherId == null) {
        var app = user.rol.rolAplications[j].aplication;
        app.SubAppSys = [];
        var bandera = false
        if (user.company) {
          if (user.company.companyAplications.length > 0) {
            for (var d = 0; d < user.company.companyAplications.length; d++) {
              var element = user.company.companyAplications[d];
              if (element) {
                if (element.aplicationId == app.id) {
                  bandera = true
                  d = user.company.companyAplications.length
                }
              }

            }
            if (bandera) {
              for (var k = 0; k < user.userAplications.length; k++) {
                var element = user.userAplications[k];
                if (element.aplication.title == app.title)
                  if (element.can_see) {
                    this.appSys.push(app);
                  }

              }
            }
          } else {
            for (var k = 0; k < user.userAplications.length; k++) {
              var element = user.userAplications[k];
              if (element.aplication.title == app.title)
                if (element.can_see) {
                  this.appSys.push(app);
                }

            }
          }
        } else {
          this.appSys.push(app);
        }
      }
    }


    for (var j = 0; j < user.rol.rolAplications.length; j++) {
      for (var z = 0; z < this.appSys.length; z++) {
        if (user.rol.rolAplications[j].aplication.fatherId == this.appSys[z].id) {
          var app2 = user.rol.rolAplications[j].aplication;

          var bandera = false
          if (user.company) {
            if (user.company.companyAplications.length > 0) {
              for (var d = 0; d < user.company.companyAplications.length; d++) {
                var element = user.company.companyAplications[d];
                if (element) {
                  if (element.aplicationId == app2.id) {
                    bandera = true
                    d = user.company.companyAplications.length
                  }
                }
              }
              if (bandera) {
                for (var k = 0; k < user.userAplications.length; k++) {
                  var element = user.userAplications[k];
                  if (element.aplication.title == app2.title)
                    if (element.can_see) {
                      this.appSys[z].SubAppSys.push(app2);
                    }

                }
              }
            } else {
              for (var k = 0; k < user.userAplications.length; k++) {
                var element = user.userAplications[k];
                if (element.aplication.title == app2.title)
                  if (element.can_see) {
                    this.appSys[z].SubAppSys.push(app2);
                  }

              }
            }
          } else {
            this.appSys[z].SubAppSys.push(app2);
          }
        }
      }
    }

    
    this.appSys = this.orderPipe.transform(this.appSys, this.order)
    this.searchAplication(this.appSys, this.location.path().substring(1));
    /* localStorage.setItem('appSys', JSON.stringify(this.appSys)); */
  }
}
