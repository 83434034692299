// src/app/auth/token.interceptor.ts

import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpHeaders,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth'
import { Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';

@Injectable()
export class fwcAPIInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // get the token from a service
    const token: string = this.getToken();

    // add it if we have one
    if (token) {
      req = req.clone({ headers: req.headers.set('Authorization', token) });
    }

    // if this is a login-request the header is
    // already set to x/www/formurl/encoded.
    // so if we already have a content-type, do not
    // set it, but if we don't have one, set it to
    // default --> json
    if (!req.headers.has('Content-Type')) {
      req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
    }


    // setting the accept header
    req = req.clone({ headers: req.headers.set('Accept', 'application/json') });
    return next.handle(req).pipe(map((event: HttpEvent<any>) => {

      if (event.type == 0) {
        return event
      }
      
      if (event instanceof HttpResponse) {
        if (event.headers.get('Authorization')) {
          console.log(event.headers.get('Authorization'))
          localStorage.setItem('token', JSON.stringify(event.headers.get('Authorization')));
        }
       
      }
      return event;
    }))
  }
  public getToken(): string {

    if (localStorage.token && localStorage.user) {
      var user = JSON.parse(localStorage.user);
      var tk = 'Bearer ' + localStorage.token + ' ' + user.rol.name
      return tk;
    }
    return "";
  }
  /*  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
     var auth: AuthService
     var router:Router
     var token = this.getToken()
   
     const authReq = req.clone({
       headers: new HttpHeaders({
         'Content-Type': 'application/json',
         'Authorization': token
       })
     });
     console.log('Intercepted HTTP call', authReq);
 
     return next.handle(authReq).pipe(map((event: HttpEvent<any>) => {
       if (event instanceof HttpResponse) {
         console.log(event.headers.get('Authorization'));
         // do stuff with response and headers you want
         event.headers
         event.body
       }
       return event;
     }))
   }
   public getToken(): string {
     
     if (localStorage.token && localStorage.user) {
       var user = JSON.parse(localStorage.user);
       var tk = 'Bearer ' + localStorage.token + ' ' + user.rol.name
       return tk;
     }
     return "";
   } */
}