import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';



const routes: Routes = [
  {
    path: 'dashboard',
    loadChildren: './dashboard/dashboard.module#DashboardModule'
  },
  {
    path: 'patients',
    loadChildren: './patient/patient.module#PatientModule'
  },
  {
    path: 'hoja-calculo/:idpatient/:type/:id',
    loadChildren: './spreadsheet/spreadsheet.module#SpreadsheetModule'
  },
  {
    path: 'catalogo',
    loadChildren: './catalogue/catalogue.module#CatalogueModule'
  },
  {
    path: 'food',
    loadChildren: './food/food.module#FoodModule'
  }
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    
  ]
})
export class PagesModule {

  constructor(){
  
  }
 }
