import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/services/user-services/user.service';
import { InfoPageService } from 'src/app/services/info-page.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private authenticationService: UserService, private infoPageService: InfoPageService) { }

 /*  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      if (localStorage.getItem('user')) {
          // logged in so return true            
          return true;
      }

      // not logged in so redirect to login page with the return url
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
      return false;
  } */
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = localStorage.user && JSON.parse(localStorage.user);
      if (user) {
        const auth = await this.authenticationService.getSingleUser(user.id);
        if(auth.hasErr && auth.login) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');
          this.infoPageService.loginTemplate();
          this.router.navigate(['/auth/signin'])
          return false
        }
        return true;          
      } 
      this.router.navigate(['/auth/signin'], { queryParams: { returnUrl: state.url }});
      return false
    }
}
