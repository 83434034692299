import { Component, TemplateRef } from '@angular/core';
import { UserService } from '../../../services/user-services/user.service';
import { Router } from '@angular/router';
import { User } from 'src/app/models/SYS/user';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  user: User
  modalRef: BsModalRef;
  message: any
  imagePath: any
  imgURL: any
  userRecord: User
  constructor(private router: Router, private userService: UserService,
    private modalService: BsModalService,private toastrService:ToastrService) {
    if (localStorage.getItem('user')) {
      this.user = new User(JSON.parse(localStorage.user))
      console.log('usuario', this.user);
    }
  }
  logout() {
    /* this.userService.logout().subscribe((logout: any) => { */
      /*   localStorage.removeItem('appSys'); */
      
    this.userService.logout(this.user.id).subscribe((logout: any) => {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      this.router.navigate(['/']);
    }, (err)=>{
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      this.router.navigate(['/']);
    })
    /* }) */
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
    this.getUserRecord()
  }
  preview(files) {
    if (files.length === 0)
      return;

    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }

    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    }
  }
  async getUserRecord() {
    var data: any = await this.userService.getSingleUser(this.user.id)
    this.userRecord = new User(data.user)
    console.log(this.userRecord)
    
    this.imgURL = this.userRecord.person.image? this.userRecord.person.image + '?' + Date.now()  : '../../../../assets/img/default.jpg?' + Date.now()
    this.user.person.image = this.userRecord.person.image + '?' + Date.now()
  }
  async onComplete() {
    var data = await this.userService.Registration(this.userRecord, this.imgURL)
    this.toastrService.success(data.message).onHidden.subscribe(() => {
      this.modalRef.hide()
      this.getUserRecord()
    });
  }
}
