import { ClassType } from './class-type';

export class Person {
    id:number;
    name: string;
    full_name: string;
    ci: string;
    last_name: string;
    second_last_name: string;
    genderId: number;
    countryId: number;
    civilStateId:number;
    cityId: number;
    provinceId: number;
    locationId: number;
    address: string;
    address_number: string;
    phone: string;
    image: any;
    birth_date: any;
    birth_place: number;
    email: string;
    mobile_phone: number;
    gender: ClassType;
    country: ClassType;
    city: ClassType;
    province: ClassType;
    location: ClassType;
    civilState:ClassType
    age:number
    constructor(person: any) {
        this.id=person.id
        this.name = person.name;
        this.full_name = person.full_name;
        this.ci = person.ci;
        this.last_name = person.last_name;
        this.second_last_name = person.second_last_name;
        this.genderId = person.genderId;
        this.countryId = person.countryId;
        this.cityId = person.cityId;
        this.civilStateId=person.civilStateId;
        this.provinceId = person.provinceId;
        this.locationId = person.locationId;
        this.address = person.address;
        this.address_number = person.address_number;
        this.phone = person.phone;
        this.image = person.image;
        this.birth_date = person.birth_date;
        this.birth_place = person.birth_place;
        this.email = person.email;
        this.mobile_phone = person.mobile_phone;
        this.gender = person.gender?new ClassType(person.gender):new ClassType({});
        this.country = person.country?new ClassType(person.country):new ClassType({});
        this.city = person.city?new ClassType(person.city):new ClassType({});
        this.province = person.province?new ClassType(person.province):new ClassType({});
        this.location = person.location?new ClassType(person.location):new ClassType({});
        this.civilState = person.civilState?new ClassType(person.civilState):new ClassType({});
    }
};
