import { ClassType } from '../GNRL/class-type';
import { CompanyAplication } from './company-aplication';

export class Company {
    id: number;
    business_name: string;
    nit: string;
    email: string;
    address: string;
    provinceId: number;
    phone: string;
    fax: string;
    website: string;
    cityId: number;
    time_zone: string;
    decimal: number;
    coinId: number;
    dollar_change: number;
    image: string;
    province: ClassType;
    city: ClassType;
    coin: ClassType;
    companyAplications:Array<CompanyAplication>;
    constructor(company: any) {
        this.id = company.id;
        this.business_name = company.business_name;
        this.nit = company.nit;
        this.email = company.email;
        this.address = company.address;
        this.provinceId = company.provinceId;
        this.phone = company.phone;
        this.fax = company.fax;
        this.website = company.website;
        this.cityId = company.cityId;
        this.time_zone = company.time_zone;
        this.decimal = company.decimal;
        this.coinId = company.coinId;
        this.dollar_change = company.dollar_change;
        this.image = company.image;
        this.province = company.province?new ClassType(company.province):new ClassType({});
        this.city = company.city?new ClassType(company.city):new ClassType({});
        this.coin =  company.coin?new ClassType(company.coin):new ClassType({});
        this.companyAplications=company.companyAplications?new Array<CompanyAplication>(...company.companyAplications):new Array<CompanyAplication>()
    }
}