
export class Type {
    id:number;
    name: String;
    nick_name:String;
    companyId:number;
    heredity: boolean;    
    father:boolean;
    constructor(type:any){
        this.id=type.id;
        this.name=type.name;
        this.nick_name=type.nick_name;
        this.companyId=type.companyId;
        this.heredity=type.heredity;
        this.father=type.father;
    }
}