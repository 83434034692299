import { Type } from './type';

export class ClassType {
  id: number;
  name: String;
  nick_name: any;
  typeId: number;
  active: boolean;
  fatherId: number;
  father: ClassType
  type:Type
  constructor(classType: any) {
    this.id = classType.id;
    this.name = classType.name;
    this.nick_name = classType.nick_name;
    this.typeId = classType.typeId;
    this.active = classType.active;
    this.fatherId = classType.fatherId;
    this.father= classType.father?new ClassType(classType.father):null;
    this.type = classType.type?new Type(classType.type):new Type({})
  }
}