import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FormsModule } from '@angular/forms';
import { NgxLoadingModule } from 'ngx-loading';
import { CachePathModule } from 'src/app/pipes/cache-pipe/cache.module';
@NgModule({
  declarations: [HeaderComponent],
  imports: [
    RouterModule,
    CommonModule,
    ModalModule.forRoot(),
    FormsModule,
    NgxLoadingModule.forRoot({}),
    CachePathModule,
  ],
  exports: [
    HeaderComponent
  ]
})
export class HeaderModule { }
