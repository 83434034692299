import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TemplateModule } from './template/template.module';
import { TemplatebModule } from './templateb/templateb.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    TemplateModule,
    TemplatebModule
  ],
  exports: [
    TemplateModule,
    TemplatebModule
  ]
})
export class LayoutModule { }
