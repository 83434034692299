
import { Company} from '../NUTR/company';
import { Rol} from './rol';
import { Person} from '../GNRL/person';
import { UserAplication} from './user-aplication';
import { ClassType } from '../GNRL/class-type';
export class User{
  id:number;
  username: string;
  password: string;
  token: string;
  active: boolean;
  date_last_accion: Date;
  user_online: boolean;
  personId: number;
  companyId: number;
  rolId: number;
  rol: Rol;
  company:Company;
  person:Person;
  userAplications:Array<UserAplication>;
  termTypeId:number;
  term_time_mount:number;
  termType:ClassType;
  expiration_date:Date;
  constructor(user: any) {
    this.id=user.id;
    this.username= user.username;
    this.password= user.password;
    this.token= user.token;
    this.active= user.active;
    this.date_last_accion= user.date_last_accion;
    this.user_online= user.user_online;
    this.personId= user.personId;
    this.companyId= user.companyId;
    this.rolId= user.rolId;
    this.rol= user.rol?new Rol(user.rol):new Rol({});
    this.company= user.company?new Company(user.company):new Company({});
    this.person= user.person?new Person(user.person):new Person({});
    this.userAplications=user.userAplications?new Array<UserAplication>(...user.userAplications):new Array<UserAplication>();
    this.termTypeId=user.termTypeId
    this.term_time_mount=user.term_time_mount
    this.termType=user.termType? new ClassType(user.termType):new ClassType({})
    this.expiration_date=user.expiration_date
  }
};
