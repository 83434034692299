import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TemplatebComponent } from './templateb.component';
import { ContentModule } from '../components/content/content.module';
import { NavbarModule } from '../components/navbar/navbar.module';

@NgModule({
  declarations: [TemplatebComponent],
  imports: [
    CommonModule,
    ContentModule,
    NavbarModule
  ],
  exports: [
    TemplatebComponent
  ]
})
export class TemplatebModule { }
