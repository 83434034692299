import { Pipe, PipeTransform } from "@angular/core";

@Pipe({name: 'orderFood'})
export class orderFoodPipe implements PipeTransform {
    transform(val){
        if(!val) return val;
        val = this.orderFood(val)
        return val;
    }
    private orderFood(foodConcepts){
        const properOrder = [
          'Desayuno',
          'Merienda matutina',
          'Sopa',
          'Segundo',
          'Refresco',
          'Postre',
          'Merienda vespertina',
          'Cena',
          'Colación Nocturna'
        ]
        const foodOrdered = []
        for (let index = 0; index < properOrder.length; index++) {
          for (let j = 0; j < foodConcepts.length; j++) {
            if(foodConcepts[j].name.toLowerCase() === properOrder[index].toLowerCase()) foodOrdered.push(foodConcepts[j])
          }
        }
        return foodOrdered
      }
}
@Pipe({name: 'orderFoodEdition'})
export class orderFoodEditionPipe implements PipeTransform {
    transform(val){
        if(!val) return val;
        val = this.orderFood2(val)
        return val;
    }
    private orderFood2(foodConcepts){
        const properOrder = [
          'Desayuno',
          'Merienda matutina',
          'Sopa',
          'Segundo',
          'Refresco',
          'Postre',
          'Merienda vespertina',
          'Cena',
          'Colación Nocturna'
        ]
        const foodOrdered = []
        console.log('fd')
        for (let index = 0; index < properOrder.length; index++) {
          for (let j = 0; j < foodConcepts.value.length; j++) {
            let foodConcept =  foodConcepts.value[j].value.foodType
            if(foodConcept.name.toLowerCase() === properOrder[index].toLowerCase()) foodOrdered.push(foodConcepts.value[j])
          }
        }
        return foodOrdered
      }
}