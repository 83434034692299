import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Token } from '@angular/compiler';
import { User } from 'src/app/models/SYS/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private actionUrl: string;
  constructor(private http: HttpClient) {
    this.actionUrl = environment.apiUrl;
  }
  public login<T>(username: string, password: string, authData: any = {}, recordType: string): Observable<T> {
    const toAdd = { username: username, password: password, authData: authData, recordType: recordType }
    return this.http.post<any>(this.actionUrl + 'autenticar', toAdd);
  }
  public relogin<T>(username: string, password: string, authData: any = {relogin:true}, recordType: string): Observable<T> {
    const toAdd = { username: username, password: password, authData: authData, recordType: recordType }
    return this.http.post<any>(this.actionUrl + 'reautenticar', toAdd);
  }
  /*   private Registration<T>(model: User): Observable<T> {
      return this.http.post<any>(this.actionUrl + 'registration', model);
    } */
  public async Registration(model: any, img: any) {
    model.person.image = img
    let data: any = await new Promise<any>(resolve =>
      this.http.post<any>(this.actionUrl + 'registration', model)
        .subscribe(
          (res: any) => {
            console.log('HTTP response', res);
            if (res.hasErr) {
              resolve([])
            } else {
              resolve(res)
            }
          },
          (err) => {
            if(err.status=== 413) {
              resolve({ hasErr: true, message: 'Petición excede el tamaño permitido 5mb.'})
            }else if(err.status=== 403){
              resolve({ hasErr: true, message: 'No autorizado.', login: true})
            }   
            else{
              resolve({ hasErr: true, message: err.statusText})
            }
          },
          () => console.log('HTTP request completed.')
        ))
    return data
  } public async ChangeStateOfUser(id: any) {
    let data: any = await new Promise<any>(resolve =>
      this.http.post<any>(this.actionUrl + 'change-state-user/' + id, {})
        .subscribe(
          (res: any) => {
            console.log('HTTP response', res);
            if (res.hasErr) {
              resolve([])
            } else {
              resolve(res)
            }
          },
          (err) => {
            if(err.status=== 413) {
              resolve({ hasErr: true, message: 'Petición excede el tamaño permitido 5mb.'})
            }else if(err.status=== 403){
              resolve({ hasErr: true, message: 'No autorizado.', login: true})
            }   
            else{
              resolve({ hasErr: true, message: err.statusText})
            }
          },
          () => console.log('HTTP request completed.')
        ))
    return data
  }
  public recoveryAcount<T>(email: string): Observable<T> {
    const toAdd = { email: email }
    return this.http.post<any>(this.actionUrl + 'recoveryAcount', toAdd);
  }


  public logout<T>(id): Observable<T> {
    return this.http.post<any>(this.actionUrl + 'logout/' + id, {})
    // remove user from local storage to log user out

  }
  public async getAllUsersPaginator(paginator: any) {
    let data: any = await new Promise<any>(resolve =>
      this.http.get<any>(this.actionUrl
        + 'users-list/' + paginator.newFilter.companyId
        + "/currentPage/" + paginator.config.currentPage
        + "/itemsPerPage/" + paginator.config.itemsPerPage
        + "/search/" + paginator.newFilter.search
        + "/column/" + paginator.column
        + "/direction/" + paginator.direction
        + "/name/" + paginator.newFilter.name
        + "/last_name/" + paginator.newFilter.last_name
        + "/second_last_name/" + paginator.newFilter.second_last_name
        + "/username/" + paginator.newFilter.username
        + "/rol/" + paginator.newFilter.rol
        + "/termType/" + paginator.newFilter.termType
        + "/expiration_date/" + paginator.newFilter.expiration_date
        + "/active/" + paginator.newFilter.active)
        .subscribe(
          (res: any) => {
            console.log('HTTP response', res);
            if (res.hasErr) {
              resolve([])
            } else {
              resolve(res)
            }
          },
          (err) => {
            if(err.status=== 413) {
              resolve({ hasErr: true, message: 'Petición excede el tamaño permitido 5mb.'})
            }else if(err.status=== 403){
              resolve({ hasErr: true, message: 'No autorizado.', login: true})
            }   
            else{
              resolve({ hasErr: true, message: err.statusText})
            }
          },
          () => console.log('HTTP request completed.')
        ))
    return data
  }

  public async getSingleUser(idUser: any) {
    let data: any = await new Promise<any>((resolve, reject) =>
      this.http.get<any>(this.actionUrl + 'user/' + idUser)
        .subscribe(
          (res: any) => {
            console.log('HTTP response', res);
            if (res.hasErr) {
              reject('fail for some reason')
            } else {
              resolve(res)
            }
          },
          (err) => {
            if(err.status=== 413) {
              resolve({ hasErr: true, message: 'Petición excede el tamaño permitido 5mb.'})
            }else if(err.status=== 403){
              resolve({ hasErr: true, message: 'No autorizado.', login: true})
            }   
            else{
              resolve({ hasErr: true, message: err.statusText})
            }
          },
          () => console.log('HTTP request completed.')
        ))
    return data
  }

  public addUser<T>(user: any): Observable<T> {
    const toAdd = JSON.stringify(user);

    return this.http.post<T>(this.actionUrl, toAdd);
  }
}
