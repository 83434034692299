import { NgModule } from '@angular/core';
import { cachePathPipe } from './cache-path.pipe';


@NgModule({
  declarations: [cachePathPipe],
  exports: [cachePathPipe],
  providers: [cachePathPipe]
})
export class CachePathModule { }
