import { RolAplication } from './rol-aplication';

export class Rol {
	id: number;
	name: string;
	rolAplications:Array<RolAplication>
	constructor(rol: any) {
		this.id=rol.id
		this.name = rol.name
		this.rolAplications=rol.rolAplications?new Array<RolAplication>(...rol.rolAplications):new Array<RolAplication>()
	}
}