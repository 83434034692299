import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({name: 'cachePath'})
export class cachePathPipe implements PipeTransform {
  constructor(protected sanitizer: DomSanitizer) {}
  transform(value) {
    if (this.isBase64(value)) {
      
        return this.sanitizer.bypassSecurityTrustUrl(value);
    }else{
      return value + '?' +  Date.now();
    }
  }

  isBase64(str) {
    try {
        var byteCharacters = str.replace(/^data:image\/(png|jpeg|jpg);base64,/, '');
        atob(byteCharacters)
        return true;
    } catch (err) {
        return false;
    }
  }
  
}