import { NgModule } from "@angular/core";
import { Routes, RouterModule, Router } from "@angular/router"
import { PagesModule } from './main/pages/pages.module';
import { AuthenticationModule } from './main/authentication/authentication.module';


const appRoutes: Routes = [
     { path: 'pages', loadChildren: './main/pages/pages.module#PagesModule' },
     { path: 'auth', loadChildren: './main/authentication/authentication.module#AuthenticationModule' },
     { path: '**', redirectTo: 'auth/signin'}
]


@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes ),
        PagesModule,
        AuthenticationModule
    ],
    exports: [
        RouterModule
    ]
})

export class AppRoutingModule{ }