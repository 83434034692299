import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TemplateComponent } from './template.component';
import { ContentModule } from '../components/content/content.module';
import { FooterModule } from '../components/footer/footer.module';
import { HeaderModule } from '../components/header/header.module';
import { NavbarModule } from '../components/navbar/navbar.module';


@NgModule({
  declarations: [TemplateComponent],
  imports: [
    RouterModule,
    CommonModule,
    ContentModule,
    FooterModule,
    HeaderModule,
    NavbarModule
  ],
  exports: [
    TemplateComponent
  ]
})
export class TemplateModule { }
