import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { LayoutModule } from './layout/layout.module';
import { PagesModule } from './main/pages/pages.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 
import { fwcAPIInterceptor } from './helpers/header';
import { ToastrModule } from 'ngx-toastr';
import { AuthGuard } from './guards/index';



declare var $: any;


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    PagesModule,
    LayoutModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 1500,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    })
  ],
  providers: [AuthGuard,{  provide: HTTP_INTERCEPTORS,
    useClass: fwcAPIInterceptor,
    multi: true
 }],
  bootstrap: [AppComponent]
})
export class AppModule { }
